import * as React from 'react';
import { EntityConfig, entityCoreFormFields, ENTITY_VALUE_FIELDS, EntityMapImage, graphql } from '@shapeable/core';
import { RestorationProject as MyType } from '@shapeable/kelp-forest-alliance-types';
import { RestorationProjectDetailsLayout } from '../components/restoration-project-details-layout';
import { RestorationProjectBanner } from '../components/restoration-project-banner';
import { RestorationProjectTooltip } from '../components/restoration-project-tooltip';
const gql = String.raw;

export const RestorationProject: EntityConfig<MyType> = {
  name: 'RestorationProject',
  slug: 'restoration-project',
  label: 'Restoration Project',
  icon: ({ icons }) => icons.Entities.ImpactStory,
  autoRoutes: ['list', 'create', 'update', 'details'],
  useFollowers: true,
  useModerators: true,
  includeInSearch: true,
  useConversation: true,
  groupName: 'Participate',
  useMap: true,
  usePublishStatus: true,
  components: {
    details: RestorationProjectDetailsLayout,
    banner: RestorationProjectBanner,
    tooltip: RestorationProjectTooltip,
  },
  map: {
    queryFields: gql`
      place { ${ENTITY_VALUE_FIELDS} }
      organisation { ${ENTITY_VALUE_FIELDS} }
      species { ${ENTITY_VALUE_FIELDS} }
    `
  },
  export: {
    queryFields: graphql`
      latitude longitude
      country { name }
      description { text }
      objective { text }
      place { name latitude longitude }
      organisations { name } 
      motivations { name } 
      contact { name organisation { name } }
      citation { url name authors { name } authorShowsEtAl edition publication accessDate startPage volume footnoteNumber year }
      video { name }
      observations {
        startDate endDate
        name
        observationNumber 
        areaOfRestoration
        successful
        areaOfProtection
        distanceToNearestKelpForestInMetres
        actionSummary { text } 
        lessonsLearned { text } 
        projectImpacts { text } 
        disturbanceDescription { text } 
        recordedBy { name }
        species { name }
        transplantAdherenceMethod { name }
        transplantLifeStage { name }
        transplantSource { name }
        reasons { name }
        causeOfDecline { text } 
        objective { text }
        motivations { name } 
        methodologies { name } 
        disturbanceTypes { name } 
        impactTypes { name } 
        totalCost capitalCost planningCost constructionCost inKindCost monitoringCost maintenanceCost costCurrency costYear
        citation { url name authors { name slug } authorShowsEtAl edition publication accessDate startPage volume footnoteNumber year }
        ecologicalPrinciples { name }
        indicatorValues {
          value startValue endValue numericValue numericStartValue numericEndValue
          indicator { 
            name title slug precision 
            unit { 
              slug prefix suffix 
              valueType { slug } 
            } 
          } 
        }
      }
      causeOfDecline { text }
      reasons { name }
    `,
    // worksheets: [
    //   {
    //     field: 'observations',
        
    //   }
    // ]
  },
  details: {
    queryFields: graphql`
      latitude longitude
      country { id slug name openGraph { id image { id url } } }
      description { id text }
      objective { id text }
      place { id name latitude longitude }
      organisation { ...EntityValueFields }
      organisations { id name slug openGraph { id description { id text } title image { id url } } country { id name } } 
      motivations { ...EntityValueFields } 
      contact { id name slug organisation { id name  } openGraph { id title image { id url } } }
      citation { id slug url name authors { id name slug } authorShowsEtAl edition publication accessDate startPage volume footnoteNumber year }
      video { ...EntityValueFields }
      observations {
        startDate endDate
        id name slug openGraph { id title description { id text } image { id url } }
        observationNumber 
        areaOfRestoration
        successful
        areaOfProtection
        distanceToNearestKelpForestInMetres
        actionSummary { id text } 
        lessonsLearned { id text } 
        projectImpacts { id text } 
        disturbanceDescription { id text } 
        recordedBy { id slug name openGraph { id image { id url } } }
        species { id slug name openGraph { id image { id url } } }
        transplantAdherenceMethod { id slug name openGraph { id image { id url } } }
        transplantLifeStage { id slug name openGraph { id image { id url } } }
        transplantSource { id slug name openGraph { id image { id url } } }
        reasons { ...EntityValueFields }
        causeOfDecline { id text } 
        objective { id text }
        motivations { ...EntityValueFields } 
        methodologies { ...EntityValueFields } 
        disturbanceTypes { ...EntityValueFields } 
        impactTypes { ...EntityValueFields } 
        totalCost capitalCost planningCost constructionCost inKindCost monitoringCost maintenanceCost costCurrency costYear
        citation { id slug url name authors { id name slug } authorShowsEtAl edition publication accessDate startPage volume footnoteNumber year }
        ecologicalPrinciples { ...EntityValueFields }
        indicatorValues {
          id value startValue endValue numericValue numericStartValue numericEndValue
          indicator { 
            id name title slug precision 
            unit { 
              id slug prefix suffix 
              valueType { id slug } 
            } 
          } 
        }
      }
      causeOfDecline { id text }
      reasons { ...EntityValueFields }
    `,
    children: ({ entities }) => [
      {
        type: 'linkedMultiple',
        entityTypeName: 'Species',
        fieldName: 'species',
        showDetailsTab: false,
      },
      {
        type: 'linkedMultiple',
        entityTypeName: 'Motivation',
        fieldName: 'motivations',
        showDetailsTab: false,
      },
      {
        type: 'linkedMultiple',
        entityTypeName: 'Observation',
        fieldName: 'observations',
        showDetailsTab: false,
      },

    ],
  },
  filters: {
    fields: {
      //motivations: { entityTypeName: 'Motivation' },
      organisations: { entityTypeName: 'Organisation' },
      methodologies: { entityTypeName: 'Methodology' },
      reasons: { entityTypeName: 'Reason' },
      species: { entityTypeName: 'Species' },
      regions: { entityTypeName: 'Region' },
      sites: { entityTypeName: 'Site' },
      country: { entityTypeName: 'Economy' },
    },
  },
  list: {
    showConversationsTab: false,
    banner: {
      content: 'Restoration projects are self identified areas with repeated restoration interventions and observations. These may be done by different organizations, using different methods, at different times but all occur in the same geographic space, such as a beach, bay, cove, island, headland, or other geographic location.',
    },
    queryFields: gql`
      country { id }
      species { id }
      observations { id }
      motivations { id } 
    `,
  },
  form: {
    modal: {
      fill: true,
      maxWidth: "1200px",
    },
    fieldSets: [
      { name: 'overview', intro: 'The overview tab reflects the restoration project or site where restoration actions and observations may occur. Information specific to a restoration action or observation is entered in the “Observations” tab.' },
      { name: 'roles' },
      { name: 'taxonomies', label: 'metadata' },
      { name: 'observations' },
    ],
    fields: {
      ...entityCoreFormFields,
      place: { type: 'linked', required: true, description: "Specify the location of the {{RESTORATION_PROJECT}} – be specific and give the best detail you can. For example, the beach, bay, headland, or island you are working on.", entityTypeName: 'Place' },
      banner: { type: 'banner', fieldSet: 'overview', description: "By default a {{GENUS}} image banner will be shown automatically, based on a {{SPECIES}} that you classify as being restored. To override this with your own selection, choose one of the {{GENUS}} banners from the menu below."},
      objective: { type: 'longText', label: "Restoration Objective", description: 'Explain the motivations and goals for conducting restoration at this site.' },
      description: { type: 'longText', label: 'Site Selection Description', description: 'Explain why this site was chosen for restoration.' },
      videos: { type: 'linkedMultiple', entityTypeName: 'Video' },
      
      motivations: { type: 'linkedMultiple', entityTypeName: 'Motivation', fieldSet: 'taxonomies' },
      citations: { type: 'linkedMultiple', entityTypeName: 'Citation' },
      observations: { type: 'linkedTable', description: 'Record a set of {{INDICATOR_VALUES}} against a specific Year and {{SPECIES}}.', entityTypeName: 'Observation', fieldSet: 'observations' },
      reasons: { type: 'linkedMultiple', entityTypeName: 'Reason' },
      causeOfDecline: { type: 'longText', description: 'What caused the kelp population to decline or disappear at this site?' },
      organisations: { type: 'linkedMultiple', required: true, entityTypeName: 'Organisation', fieldSet: 'roles'},
      contacts: { type: 'linkedMultiple', label: 'Contacts', description: "Who are the Contacts for this {{RESTORATION_PROJECT}}?", entityTypeName: 'Person', fieldSet: 'roles' },
      sites: { type: 'linkedMultiple', fieldSet: 'admin', entityTypeName: 'Site', description: 'Which {{SITES}} should this {{RESTORATION_PROJECT}} appear on? (Note: this does not automatically setup a {{SITE}}, its purpose is to provide the ability for developers to include this {{RESTORATION_PROJECT}} only in the {{SITES}} specified here)' },

    }
  }
};